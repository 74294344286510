// src/config.js
const config = {
	// API_BASE_URL: 'http://corithm.localpro/wp-json/corithm/v1',
	// HOME_URL: 'http://localhost:3000',

	API_BASE_URL: 'https://corithm.com/wp/wp-json/corithm/v1',
	HOME_URL: 'https://corithm.com',
};

export default config;
