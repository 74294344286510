import '../../fonts.css';
import './footer.css';

function Footer() {

	return (
		<>
			<footer className="footer-section text-white">
				<div className="container-fluid py-3">
					<div className="row">
						<div className="col-md-12">
							<div className="footer-text">
								© {new Date().getFullYear()} Corithm Technologies Pvt. Ltd. All Rights Reserved
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer;
