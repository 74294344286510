import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from './Components/Header/Header';
import Company from './Components/Companies/Company';
import Contact from './Components/Contact/contact'
import Footer from './Components/Footer/footer'
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Header/>
		<Company/>
		<Contact/>
		<Footer/>
	</React.StrictMode>
);

// Register the service worker
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
