import '../../fonts.css';
import './contact.css';

import React, {useState, useRef} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../config';
import axios from 'axios';

function Contact() {

	const recaptchaRef = useRef(null);

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [recaptchaValue, setRecaptchaValue] = useState(null);
	const [responseMsg, setResponseMsg] = useState('');
	const [responseType, setResponseType] = useState('');
	const [isLoading, setIsLoading] = useState(false);


	const handleSubmit = async (event) => {
		event.preventDefault();

		// reset.
		setResponseMsg('');
		setResponseType('');

		setIsLoading(true); // Start loading

		// Construct form data object
		const formData = {
			name: name,
			phone: phone,
			email: email,
			subject: subject,
			message: message,
			'g-recaptcha-response': recaptchaValue
		};

		// Set the path to your REST API endpoint
		const apiEndpoint = `${config.API_BASE_URL}/submit-form/`;

		// Use Axios to send a POST request
		axios.post(apiEndpoint, formData)
		.then(response => {
			// Handle success
			console.log(response.data);

			setResponseMsg('Message sent successfully');
			setResponseType('success');

			// Reset form fields and reCAPTCHA
			setName('');
			setPhone('');
			setEmail('');
			setSubject('');
			setMessage('');
			setRecaptchaValue(null);

			if (recaptchaRef.current) {
				recaptchaRef.current.reset();
			}
		})
		.catch(error => {
			// Handle error
			setResponseMsg(error.response.data.message)
			setResponseType('error')
		}).finally(() => {
			setIsLoading(false);
		});
	};

	return (
		<>
			<section id="contact-us" className="contact-section">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h2>Got a Question?</h2>
							<div className="form-container">
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-md-6 mb-3">
											<input type="text" className="form-control" id="firstName"
											       placeholder="Name" value={name}
											       onChange={e => setName(e.target.value)} required/>
										</div>
										<div className="col-md-6 mb-3">
											<input type="tel" className="form-control" id="phone" placeholder="Phone"
											       value={phone} onChange={e => setPhone(e.target.value)} required/>
										</div>
									</div>
									<div className="mb-3">
										<input type="email" className="form-control" placeholder="Email" id="email"
										       value={email} onChange={e => setEmail(e.target.value)} required/>
									</div>
									<div className="mb-3">
										<input type="text" className="form-control" placeholder="Subject" id="subject"
										       value={subject} onChange={e => setSubject(e.target.value)} required/>
									</div>
									<div className="mb-3">
										<textarea placeholder="Enter your message..." className="form-control"
										          id="message" rows="3" value={message}
										          onChange={e => setMessage(e.target.value)} required></textarea>
									</div>
									<div className="mb-3">
										<ReCAPTCHA
											ref={recaptchaRef}
											sitekey="6LeJcR8pAAAAAFC2qciDNHGcpVWObRLuElnsmdTZ"
											onChange={value => setRecaptchaValue(value)}
										/>
									</div>
									<button type="submit" className="btn submit-button btn-primary"
									        disabled={!recaptchaValue || isLoading}>
										{isLoading ? (
											<>
												<span className="spinner-border spinner-border-sm" role="status"
												      aria-hidden="true"></span>
												<span className="sr-only">Loading...</span>
											</>
										) : (
											'Send Message'
										)}
									</button>


									{ // Display success message.
										responseMsg && (
											<>
												<div className={`alert alert-${responseType} ${responseType}-msg`}>
													<p>{responseMsg}</p>
												</div>
											</>
										)}

								</form>
							</div>
						</div>
						<div className="col-md-6">
							<div className="right-section">
								<h2>Let's Work <span className="highlight">Together</span></h2>
								<div className="description-txt">
									Do you know what it takes grow your <br/>
									business online? Do you want to?
								</div>
								<div className="phone">
									<a href="tel:+923002475557">+92 300 247 555 7</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Contact;
