import '../../fonts.css';
import './company.css';

import React, {useState, useEffect} from 'react';
import config from '../../config';

function Company() {

	const [companies, setCompanies] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetch(`${config.API_BASE_URL}/companies`)
		.then(response => response.json())
		.then(data => {
			setCompanies(data);
			setLoading(false);
		})
		.catch(error => {
			console.error('Error fetching data: ', error);
			setLoading(false);
		});
	}, []);

	if (loading) {
		return (
			<>
				<div className="container company-section p-5">
					<div className="row">
						<div className="row">
							<div className="col-md-12 pb-3 text-center">
								<h2 className="heading">OUR COMPANIES</h2>
							</div>
						</div>
						<div className="col-md-4">
							<div className="skeleton skeleton-box">
								<div className="skeleton-header"></div>
								<div className="skeleton-text-short"></div>
								<div className="skeleton-text"></div>
								<div className="skeleton-button"></div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="skeleton skeleton-box">
								<div className="skeleton-header"></div>
								<div className="skeleton-text-short"></div>
								<div className="skeleton-text"></div>
								<div className="skeleton-button"></div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="skeleton skeleton-box">
								<div className="skeleton-header"></div>
								<div className="skeleton-text-short"></div>
								<div className="skeleton-text"></div>
								<div className="skeleton-button"></div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<section id="companies-section" className="company-section">
				<div className="container">
					<div className="row">
						<div className="col-md-12 pt-5 pb-3 text-center">
							<h2 className="heading">OUR COMPANIES</h2>
						</div>
					</div>
					<div className="row">
						{companies.map(company => (
							<div className="col-md-4 responsive-margin">
								<div className="company-card">
									<div className="company-card-img">
										<img
											src={company.img_url ? company.img_url : 'https://via.placeholder.com/300x150'}
											alt={company.title}/>
									</div>
									<div className="company-card-content">
										<h3 className="mb-3">{company.title}</h3>
										<p className="excerpt">{company.excerpt}</p>
										<a target="_blank" href={company.acf.website_url + '?utm_source=corithm'}
										   className="btn btn-outline-secondary text-white">Read More</a>
									</div>
								</div>
							</div>
						))}

					</div>
				</div>
			</section>
		</>
	)
}

export default Company;
