import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import logoWhite from '../../assets/images/logo-white.png';
import config from '../../config';

const Header = () => {
	return (
		<>
			<header className="header-section">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="nav-and-email-container">
								<nav className="navbar navbar-expand-lg navbar-light bg-transparent">
									<div className="container-fluid">
										<a className="navbar-brand" href={config.HOME_URL}>
											<img src={logoWhite} alt="Company Logo" />
										</a>
										<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
											<span className="navbar-toggler-icon"></span>
										</button>
										<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
											<ul className="navbar-nav">
												<li className="nav-item">
													<a className="nav-link" aria-current="page" href="#companies-section">Our Companies</a>
												</li>
												<li className="nav-item">
													<a className="nav-link" href="#contact-us">Contact Us</a>
												</li>
											</ul>
										</div>
									</div>
								</nav>
								<div className="email-section">
									<a className="btn btn-primary" href="mailto:info@corithm.com">INFO@CORITHM.COM</a>
								</div>
							</div>
							<div className="header-content">
								<h1><div>FOCUS ON BUSINESS </div><div className="big-txt">& IT SERVICES</div></h1>
								<p>YOUR BUSINESS GOAL IS NOT ONLY PROFIT BUT GROWTH ALSO.</p>
								<a href="#contact-us" className="btn btn-primary btn-lg"><span>GET STA</span>RTED NOW</a>
							</div>
						</div>
					</div>
				</div>

			</header>
		</>
	);
};

export default Header;
